document.addEventListener("DOMContentLoaded", (event) => {
  const programEls = document.querySelectorAll('[name="program"]');

  // Extract selected program from select element or checkbox
  let initalProgram;
  if (programEls.length > 1) {
    initalProgram = document.querySelector('[name="program"]:checked').value;
  } else if (programEls.length === 1) {
    initalProgram = programEls[0].value;
  }

  if (programEls.length == 0) {
    return;
  }

  const current_phase = JSON.parse(
    document.getElementById("current-phase").textContent
  );

  fetchPhases(initalProgram, current_phase);
  programEls.forEach((programEl) => {
    programEl.addEventListener("change", (e) => {
      const program = e.target.value;
      fetchPhases(program, current_phase);
    });
  });

  function renderOption({ value, name, selected }) {
    return `<option value="${value}" ${
      selected ? "selected" : ""
    }>${name}</option>`;
  }

  function fetchPhases(program, selected_phase, exclude_current_phase = false) {
    if (program === "") {
      return;
    }
    let url = "/programs/" + program + "/phases";
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let options = '<option value="">Select Phase</option>';

        options += data.phases
          .map((phase) => {
            return renderOption({
              value: phase.value,
              name: phase.label,
              selected: selected_phase == phase.value,
            });
          })
          .join("");

        document.querySelector('[name="phase"]').innerHTML = options;
      });
  }
});
