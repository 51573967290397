document.addEventListener("DOMContentLoaded", (event) => {
  const attendanceEls = document.querySelectorAll(".attendance-deep-link");

  const token = JSON.parse(
    document.getElementById("session-data").textContent
  ).api_key;

  attendanceEls.forEach((attendanceEl) => {
    attendanceEl.addEventListener("click", (elem) => {
      const attendanceId = elem.target.dataset.attendance;
      fetchDeepLink(attendanceId);
    });
  });
  function fetchDeepLink(attendanceId) {
    const url = `/api/appointments/${attendanceId}/deep-link/`;

    fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong. Refresh page and try again.");
      })
      .then((data) => {
        const deepLink = data.deep_link;
        const link = document.querySelector(
          `[data-attendance='${attendanceId}']`
        );
        const deepLinkDiv = document.createElement("div");
        const clipboardCopyBtn = document.createElement("button");
        clipboardCopyBtn.innerHTML = `<span class="text-sm">Copy to clipboard <i class="fas fa-clipboard"></i></span>`;
        clipboardCopyBtn.addEventListener("click", (elem) => {
          handleCopy(elem, deepLink);
        });
        deepLinkDiv.innerHTML = `<div class="text-xs overflow-hidden mb-2">${deepLink}</div>`;
        deepLinkDiv.appendChild(clipboardCopyBtn);

        link.replaceWith(deepLinkDiv);
      })
      .catch((err) => {
        const link = document.querySelector(
          `[data-attendance='${attendanceId}']`
        );
        const deepLinkDiv = document.createElement("div");
        deepLinkDiv.innerHTML = `<div class="text-sm text-red-900 font-bold">${err}</div>`;
        link.replaceWith(deepLinkDiv);
      });
  }

  function handleCopy(elem, input) {
    navigator.clipboard.writeText(input);
    elem.target.innerHTML = `<span class="text-sm">Copied <i class="fas fa-check text-green-700"></i></span>`;

    setTimeout(() => {
      elem.target.innerHTML = `<span class="text-sm">Copy to clipboard <i class="fas fa-clipboard"></i></span>`;
    }, 2000);
  }
});
