import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import "@fortawesome/fontawesome-free/js/all";
import "./sprinkles/programs.js";
import "./sprinkles/appointmentDeepLink.js";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

// boot tippy
function bootTippy() {
  tippy("[data-tippy-content]");

  tippy("[data-tippy-content-id]", {
    content(reference) {
      const id = reference.getAttribute("data-tippy-content-id");
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
  });
}

window.bootTippy = bootTippy;

Alpine.plugin(
  Clipboard.configure({
    onCopy: () => {
      console.log("Copied!");
    },
  })
);

window.Alpine = Alpine;
window.Alpine.start();

function addDataConfirmHandlers() {
  const dataConfirm = document.querySelectorAll("[data-confirm]");
  dataConfirm.forEach((element) => {
    element.addEventListener("click", (e) => {
      const message = element.dataset.confirm;
      if (window.confirm(message)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", addDataConfirmHandlers, false);
document.addEventListener("DOMContentLoaded", bootTippy, false);
